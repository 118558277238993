import { Dialog, Transition } from '@headlessui/react';
import { ButtonPublic } from 'components/Button/ButtonPublic';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import cn from 'classnames';
import { useCardanoWallet, useWalletStore } from '#lib/wallet/WalletContext';
import { useAlert } from '#lib/utils/Alert';
import { LearnMoreHelp } from '#components/InlineHelp/LearnMoreHelp';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import { SUPPORTED_WALLETS_META } from '#types/cardano';

type ConnectWalletProps = {
  title?: string;
  className?: string;
  fullWidthButton?: boolean;
  onEnabled?: () => void;
  onLoggedIn?: () => void;
  navMode?: boolean;
};

export default function ConnectWallet({
  title,
  className,
  onEnabled,
  onLoggedIn,
  fullWidthButton = false,
  navMode,
}: ConnectWalletProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { showError } = useAlert();

  const error = useWalletStore((s) => s.error);
  const clearError = useWalletStore((s) => s.clearError);

  const {
    availableWallets,
    setWallet,
    wallet,
    login,
    waitingForWalletLogin,
    tryLoadingAgain,
    clearWallet,
  } = useCardanoWallet();

  const { loadingWalletAddress, connecting } = useWalletStore((s) => ({
    loadingWalletAddress: s.loadingWalletAddress,
    connecting: s.connecting,
  }));

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    console.log('open modal');
    if (availableWallets?.length === 0 || !availableWallets) {
      tryLoadingAgain().then(() => setIsOpen(true));
    } else {
      setIsOpen(true);
    }
  }

  const [loading, setLoading] = useState(true);

  useEffect(() => {
      tryLoadingAgain()
  }, []);

  useEffect(() => {
    if (loadingWalletAddress === false) {
      setLoading(false);
    }
  }, [loadingWalletAddress]);

  return (
    <>
      <div className={cn('flex md:hidden items-center justify-end', className)}>
        {navMode ? (
          <a
            className="bg-gray-900 px-2 py-2 text-base text-white border border-gray-300 dark:border-gray-700/50 rounded-full hover:scale-105 transform duration-200 hover:shadow-lg hover:-translate-y-1 transition cursor-pointer "
            onClick={openModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
              />
            </svg>
          </a>
        ) : (
          <div className={cn('flex items-center justify-center', className)}>
            <a
              className={cn(
                'bg-gray-900 w-full text-center  px-[16px] xl:px-8 py-2.5 text-base text-white border border-gray-300 dark:border-gray-700/50 rounded-full hover:scale-105 transform duration-200 hover:shadow-lg hover:-translate-y-1 transition cursor-pointer ',
                {
                  ' min-w-full ': fullWidthButton,
                }
              )}
              onClick={openModal}
            >
              {title ? title : 'Connect Wallet'}
            </a>
          </div>
        )}
      </div>
      <div
        className={cn('hidden md:flex items-center justify-center', className)}
      >
        <a
          className={cn(
            'bg-gray-900 w-full text-center  px-[16px] xl:px-8 py-2.5 text-base text-white border border-gray-300 dark:border-gray-700/50 rounded-full hover:scale-105 transform duration-200 hover:shadow-lg hover:-translate-y-1 transition cursor-pointer ',
            {
              ' min-w-full ': fullWidthButton,
            }
          )}
          onClick={openModal}
        >
          {title ? (
            title
          ) : loading ? (
            <div className="h-6 w-16  px-2">
              <div className="loading loading-dots loading-sm " />
            </div>
          ) : (
            'Connect Wallet'
          )}
        </a>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {error ? (
                <div className="inline-block w-full max-w-xl h-full p-12 my-12 overflow-hidden text-center align-middle transition-all transform bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700/50 border shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-4xl font-bold sm:leading-6 text-gray-800 dark:text-gray-100"
                  >
                    Error Connecting Wallet
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-lg mt-8 text-gray-700 dark:text-gray-200 font-light flex items-center justify-center h-4">
                      {error}
                    </p>
                    <div className="flex flex-col dark:text-white font-medium text-lg gap-3 mt-6 mb-10">
                      <ButtonPublic onClick={() => clearError()}>
                        Okay
                      </ButtonPublic>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="inline-block w-full max-w-xl h-full p-6 sm:p-12 my-12 overflow-hidden text-center align-middle transition-all transform bg-white dark:bg-gray-900 border-gray-300 dark:border-gray-700/50 border shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-4xl font-bold leading-6 text-gray-800 dark:text-gray-100"
                  >
                    Connect
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-lg mt-8 text-gray-700 dark:text-gray-200 font-light flex items-center justify-center h-4">
                      {!availableWallets ||
                        (!!availableWallets && availableWallets.length == 0 && (
                          <span>Install and activate a supported wallet:</span>
                        ))}
                      {!wallet &&
                        !!availableWallets &&
                        availableWallets.length > 0 && (
                          <span>Connect one of your installed wallets:</span>
                        )}

                      {!!wallet && (
                        <span>
                          {!waitingForWalletLogin && (
                            <>Connect with a supported wallet:</>
                          )}
                          {waitingForWalletLogin && (
                            <span className="loading loading-dots loading-lg " />
                          )}
                        </span>
                      )}
                    </p>
                  </div>

                  <>
                    <div className="flex flex-col dark:text-white font-medium text-lg gap-3 mt-6 mb-10">
                      {SUPPORTED_WALLETS_META.filter((ww) => {
                        return isBrowser
                          ? true
                          : ww.mobileSupported === true;
                      }).map((wallet) => {
                        const w = availableWallets?.find(
                          (aw) => aw.internalName == wallet.internalName
                      );
                        return (
                          <div key={wallet.name}>
                            <div className="group border dark:border-gray-700/50 flex flex-row items-center bg-gray-50 hover:bg-white hover:shadow-md hover:scale-102 border-gray-300 dark:bg-gray-800 rounded-full hover:-translate-y-1 duration-200 cursor-pointer">
                              {!w && (
                                <Link
                                  href={
                                    isMobile
                                      ? wallet.deeplinkURL ?? wallet.install
                                      : wallet.install
                                  }
                                  target={'_blank'}
                                >
                                  <a
                                    className="flex flex-row justify-between w-full rounded-full px-6 py-3 "
                                    target={'_blank'}
                                  >
                                    <div className="flex flex-row gap-4 items-center">
                                      <img
                                        src={wallet.icon}
                                        className="w-8 h-8 object-fit"
                                        alt={'icon'}
                                      />

                                      {wallet.name}
                                    </div>
                                    <p>Install</p>
                                  </a>
                                </Link>
                              )}
                              {!!w && (
                                <a
                                  className="flex flex-row justify-between w-full rounded-full px-6 py-3 "
                                  onClick={() => {
                                    if (loading) return;
                                    setWallet(w)
                                      .then((wal) => {
                                        onEnabled && onEnabled();
                                        if (!wal) return;
                                        requestAnimationFrame(() => {
                                          login(wal)
                                            .then(() => {
                                              onLoggedIn && onLoggedIn();
                                              console.log('wallet connected');
                                              firebase
                                                .analytics()
                                                .logEvent('wallet_connect');
                                            })
                                            .catch((err) => {
                                              clearWallet();
                                              showError({
                                                message: err.message,
                                                title: 'Error',
                                              });
                                            });
                                        });
                                      })
                                      .catch((err) =>
                                        showError({
                                          message: err.message,
                                          title: 'Error',
                                        })
                                      );
                                  }}
                                >
                                  <div className="flex flex-row gap-4 items-center">
                                    <img
                                      src={wallet.icon}
                                      className="w-8 h-8 object-fit"
                                      alt={'icon'}
                                    />
                                    {wallet.name}
                                  </div>
                                  <p>Connect</p>
                                </a>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>

                  <span className=" text-gray-500">
                    Remember to{' '}
                    <a
                      onClick={() => window.location.reload()}
                      className="text-red-500 cursor-pointer"
                    >
                      refresh
                    </a>{' '}
                    your browser after you have installed a new wallet
                  </span>
                  <BrowserView>
                    <div className="mt-8">
                      <LearnMoreHelp
                        description={'Setup your web wallet'}
                        link={
                          'https://intercom.help/dropspot/en/articles/6188523-how-do-i-set-up-cardano-web-wallet'
                        }
                      />
                    </div>
                  </BrowserView>
                </div>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
